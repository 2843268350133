import { makeStyles } from '@material-ui/core';
import {
  EstimateModule,
  Flex,
  StepCard,
  StepCardTitle,
  Text,
} from 'component-library';
import {
  PayrollTierEnum,
  ProgramNameEnum,
  Programs,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
} from 'lib/constants';
import { CentsToDisplayString, TitleCase, useEffectOnce } from 'lib/helpers';
import { ProgramData } from 'lib/interfaces';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import { ClientReviewStep } from './steps/ClientReviewStep';
import { FinishedStep } from './steps/FinishedStep';

const useStyles = makeStyles(() => ({
  stepContainer: {
    marginTop: '36px',
  },
}));

interface RedemptionStepsProps {
  federalRDProgram: ProgramData;
  programs: ProgramData[];
  status?: 'available' | 'exhausted';
}

export const RedemptionSteps = observer(
  ({ federalRDProgram, programs, status }: RedemptionStepsProps) => {
    const classes = useStyles();
    const { form8974, creditEstimates } = useTaxCreditsStores();
    const [complete, setComplete] = useState<boolean>(false);
    const featureFlags = useFeatureFlags();

    const { orderForm, qualificationStatus, stage, taxYear, subStage } =
      federalRDProgram;

    const chipTitles = programs
      .filter(
        (program: ProgramData) => program.name === ProgramNameEnum.FED_RD_TAX,
      )
      .map((program: ProgramData) => TitleCase(Programs[program.name].geo));

    const currentAmount = creditEstimates.currentAmountByYear.get(taxYear) ?? 0;
    const estimateTitle =
      creditEstimates.estimateTitleByYear.get(taxYear) ?? '';
    const estimateTooltip =
      creditEstimates.estimateTooltipByYear.get(taxYear) ?? '';
    const acceptedOrderForm = orderForm?.acceptedAt !== null;
    const showEstimatesModule =
      (stage === ProgramStageEnum.EXPENSE_CLASSIFICATION ||
        stage === ProgramStageEnum.MS_REVIEW ||
        stage === ProgramStageEnum.FINISHED ||
        stage === ProgramStageEnum.CLIENT_REVIEW) &&
      qualificationStatus === QualificationStatusEnum.QUALIFIED &&
      acceptedOrderForm &&
      featureFlags.showCreditEstimatesModule &&
      currentAmount !== 0;
    const showFinishedStep =
      stage === ProgramStageEnum.FINISHED &&
      subStage === ProgramSubStageEnum.READY_TO_REDEEM;

    useEffectOnce(() =>
      setComplete(form8974.programIsComplete(federalRDProgram)),
    );

    return (
      <div className={classes.stepContainer}>
        <StepCard dataTestId='step-card-component'>
          <StepCardTitle
            chipTitles={chipTitles}
            dataTestId='step-card-title'
            title={`${taxYear} Filing`}
          />
          {showEstimatesModule ? (
            <EstimateModule
              title={estimateTitle}
              titleTooltip={estimateTooltip}
              currentEstimate={CentsToDisplayString(currentAmount)}
              isLoading={creditEstimates.isLoading}
              isFinalCredit={stage === ProgramStageEnum.CLIENT_REVIEW}
            />
          ) : (
            <></>
          )}
          <>
            {stage === ProgramStageEnum.CLIENT_REVIEW && (
              <ClientReviewStep
                federalRDProgram={federalRDProgram}
                programs={programs}
              />
            )}
            {showFinishedStep ? (
              <FinishedStep
                federalRDProgram={federalRDProgram}
                complete={complete}
                setComplete={setComplete}
                programs={programs}
              />
            ) : (
              <>
                {form8974.payrollTier !== PayrollTierEnum.TIER_3 &&
                federalRDProgram.payrollProviderSetupCompleted8974 ? (
                  <Flex padding={[8, 24, 16, 24]}>
                    <Text>
                      You have completed all actions to redeem your {taxYear}{' '}
                      tax credits.
                    </Text>
                  </Flex>
                ) : (
                  <>
                    {status === 'available' ? (
                      <Flex padding={[8, 24, 16, 24]}>
                        <Text>
                          You must return to redeem your payroll tax credits
                          each quarter
                        </Text>
                      </Flex>
                    ) : (
                      <Flex padding={[8, 24, 16, 24]}>
                        <Text>
                          You have completed all actions to redeem your{' '}
                          {taxYear} tax credits.
                        </Text>
                      </Flex>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </StepCard>
      </div>
    );
  },
);
