import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseTaxCreditsStore } from '../BaseTaxCreditsStore';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import { runInAction } from 'mobx';
import { ProgramNameEnum, ProgramStageEnum } from 'lib/constants';
import {
  CreditEstimate,
  PayrollDataSourceEnum,
  ProgramData,
} from 'lib/interfaces';

export class CreditEstimateStore extends BaseTaxCreditsStore {
  public isLoading = true;
  public previousAmountByYear = new Map<number, number>();
  public currentAmountByYear = new Map<number, number>();
  public estimateTitleByYear = new Map<number, string>();
  public estimateDescriptionByYear = new Map<number, string>();
  public estimateTooltipByYear = new Map<number, string>();
  public creditNotYetRedeemed = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  /**
   * Populates the class properties below to be used in the Credit Module
   * display on the Tax Credits Page:
   *
   * this.previousAmountByYear
   * this.currentAmountByYear
   * this.isLoading
   * this.estimateTitleByYear
   * this.estimateTooltipByYear
   */
  public async getCreditEstimates(programId: number) {
    const { programs } = this.rootStore.common.companyStore.currentCompany;
    const currentProgram = programs.find((p) => p.id === programId);
    const currentProgramOrderForm = currentProgram?.orderForm;
    const creditEstimateHistory =
      await this.getLatestManualEstimateAfterDeadline(programId);

    if (currentProgram && currentProgramOrderForm) {
      const { taxYear } = currentProgram;
      const previousAmount = currentProgramOrderForm.estimatedTotalCreditCents;

      if (creditEstimateHistory && creditEstimateHistory.latestManualEstimate) {
        const currentAmount =
          creditEstimateHistory.latestManualEstimate.estimateCents;

        runInAction(() => {
          if (previousAmount && currentAmount !== previousAmount) {
            this.previousAmountByYear.set(taxYear, previousAmount);
          }
          this.currentAmountByYear.set(taxYear, currentAmount);
        });

        await this.getEstimateDescription(
          currentProgram,
          creditEstimateHistory.latestManualEstimate,
        );
      } else {
        runInAction(() => {
          this.currentAmountByYear.set(taxYear, previousAmount);
        });

        await this.getEstimateDescription(currentProgram);
      }

      runInAction(() => {
        this.isLoading = false;
        this.estimateTitleByYear.set(
          taxYear,
          'Official Federal R&D Credit Estimate',
        );
        this.estimateTooltipByYear.set(
          taxYear,
          'This credit estimate is based on your payroll data and the R&D efforts of similar companies.',
        );
      });
    }
  }

  /**
   * Supporting function for {@link getCreditEstimates}
   */
  public getEstimateDescription(
    currentProgram: ProgramData,
    latestManualEstimate?: CreditEstimate,
  ) {
    if (currentProgram) {
      const { taxYear } = currentProgram;
      /// Fed R&D in EC stage
      if (currentProgram.stage === ProgramStageEnum.EXPENSE_CLASSIFICATION) {
        if (latestManualEstimate && this.currentAmountByYear.get(taxYear)) {
          const currentAmount = this.currentAmountByYear.get(taxYear) ?? 0;
          const previousAmount = this.previousAmountByYear.get(taxYear) ?? 0;

          if (previousAmount !== 0 && currentAmount > previousAmount) {
            runInAction(() =>
              this.estimateDescriptionByYear.set(
                taxYear,
                `Your official credit estimate has increased! If you miss your deadline to complete the year-end assessment, your billing will be updated according to this new estimate.`,
              ),
            );
          } else {
            runInAction(() =>
              this.estimateDescriptionByYear.set(
                taxYear,
                `Once you submit your year-end assessment, we will calculate your final credit amount. The sooner you complete this step, the sooner you can redeem your credits!`,
              ),
            );
          }
        } else {
          runInAction(() =>
            this.estimateDescriptionByYear.set(
              taxYear,
              `Once you submit your year-end assessment, we will calculate your final credit amount. The sooner you complete this step, the sooner you can redeem your credits!`,
            ),
          );
        }
      }
      /// Fed R&D in MS_REVIEW stage
      if (currentProgram.stage === ProgramStageEnum.MS_REVIEW) {
        runInAction(() =>
          this.estimateDescriptionByYear.set(
            taxYear,
            `We are calculating your final credit amount now. If it differs from your official credit estimate, we will adjust your billing accordingly.`,
          ),
        );
      }
    }
  }

  public async getFinalCreditAmount(taxYear: number, programId: number) {
    const { programs } = this.rootStore.common.companyStore.currentCompany;
    const currentPrograms = programs.filter((p) => p.taxYear === taxYear);
    let creditAmountCentTotal = 0;
    let isInCreditRedemptionStep = false;
    if (currentPrograms.length > 0) {
      currentPrograms.forEach((p) => {
        if (p.name !== ProgramNameEnum.FED_RD_TAX) {
          if (
            p.stage === ProgramStageEnum.CLIENT_REVIEW ||
            p.stage === ProgramStageEnum.FINISHED ||
            p.stage === ProgramStageEnum.COMPLETED
          ) {
            creditAmountCentTotal = p.creditAmountCents + creditAmountCentTotal;
          }
        } else {
          isInCreditRedemptionStep = p.stage === ProgramStageEnum.FINISHED;
          creditAmountCentTotal = p.creditAmountCents + creditAmountCentTotal;
        }
      });

      runInAction(() =>
        this.currentAmountByYear.set(taxYear, creditAmountCentTotal),
      );

      await this.getFinalCreditEstimate(
        currentPrograms,
        programId,
        taxYear,
        isInCreditRedemptionStep,
      );
    }
  }

  /**
   * Supporting function for {@link getFinalCreditAmount}*/
  public async getFinalCreditEstimate(
    currentPrograms: ProgramData[],
    programId: number,
    taxYear: number,
    isInCreditRedemptionStep: boolean,
  ) {
    const creditEstimateHistory =
      await this.getLatestManualEstimateAfterDeadline(programId);

    if (currentPrograms.length > 0) {
      let creditEstimateTotal = 0;

      currentPrograms.forEach((p) => {
        const orderForm = p.orderForm;

        if (orderForm) {
          if (p.name !== ProgramNameEnum.FED_RD_TAX) {
            if (
              p.stage !== ProgramStageEnum.DISQUALIFIED &&
              p.stage !== ProgramStageEnum.CANCELED &&
              orderForm.acceptedAt !== null
            ) {
              const stateOrderFormEstimate =
                orderForm.estimatedTotalCreditCents;

              creditEstimateTotal =
                creditEstimateTotal + stateOrderFormEstimate;
            }
          } else {
            const fedOrderFormEstimate = orderForm.estimatedTotalCreditCents;

            if (
              creditEstimateHistory &&
              creditEstimateHistory.latestManualEstimate
            ) {
              creditEstimateTotal =
                creditEstimateTotal +
                creditEstimateHistory.latestManualEstimate.estimateCents;
            } else {
              creditEstimateTotal = creditEstimateTotal + fedOrderFormEstimate;
            }
          }
        }
      });

      runInAction(() =>
        this.previousAmountByYear.set(taxYear, creditEstimateTotal),
      );
    }

    runInAction(() => {
      const currentAmount = this.currentAmountByYear.get(taxYear) ?? 0;
      const previousAmount = this.previousAmountByYear.get(taxYear) ?? 0;

      this.estimateTitleByYear.set(taxYear, 'Total tax credits');
      this.estimateTooltipByYear.set(
        taxYear,
        `All tax credits you earned for the tax year ${taxYear}`,
      );
      if (currentAmount > previousAmount) {
        this.estimateDescriptionByYear.set(
          taxYear,
          `Congratulations! After accounting for additional information, your final credit amount is even greater than expected. Your billing ${
            isInCreditRedemptionStep ? 'has been' : 'will be'
          } updated accordingly.`,
        );
      } else if (currentAmount < previousAmount) {
        this.estimateDescriptionByYear.set(
          taxYear,
          `Your credits are here! Because your final credit amount is lower than previously estimated, ${
            isInCreditRedemptionStep ? 'we lowered' : `we'll lower`
          } your fees so you never pay more than you should.`,
        );
      } else if (currentAmount === previousAmount) {
        this.previousAmountByYear.set(taxYear, 0);
        this.estimateDescriptionByYear.set(
          taxYear,
          `Congratulations! Your final credits are here. Ask your CPA to file your taxes soon to redeem your credits early!`,
        );
      }
      this.isLoading = false;
    });
  }

  /**
   * Returns the latest credit estimate
   */
  public async getLatestEstimate(programId: number) {
    const res = await this.api.GetCreditEstimates(programId);

    if (res?.errorMsg) {
      datadogLogs.logger.error(
        `[CREDIT_ESTIMATE]: Error retreiving credit estimates for program_id: ${programId}`,
        logContext({
          company: this.rootStore.common.companyStore.currentCompany,
          error: res.errorMsg,
        }),
      );
    }

    if (res?.data && res?.data?.creditEstimates.length > 0) {
      const estimatesDescending = res.data.creditEstimates.sort((a, b) => {
        // Since ids are auto incremented they should suffice to determine which estimate is the latest when created is undefined
        return b.id - a.id;
      });

      return estimatesDescending[0];
    }
  }

  /**
   * Returns the latest manual credit estimate if we are past the YEA Deadline
   *
   * Logic: After the YEA Deadline any manually provided estimate should supersede
   * All other estimates even if the manual estimate is not the most recent estimate
   *
   * Supporting function for {@link getCreditEstimates} and {@link getFinalCreditEstimate}
   * */
  public async getLatestManualEstimateAfterDeadline(programId: number) {
    const res = await this.api.GetCreditEstimates(programId);
    const yeaDeadline = new Date('March 10, 2023').getTime();
    let latestManualEstimate: CreditEstimate;

    if (res?.errorMsg) {
      datadogLogs.logger.error(
        `[CREDIT_ESTIMATE]: Error retrieving credit estimates for program_id: ${programId}`,
        logContext({
          company: this.rootStore.common.companyStore.currentCompany,
          error: res.errorMsg,
        }),
      );
    }

    if (res?.data && res?.data?.creditEstimates.length > 0) {
      // Filter out duplicates
      // TODO: fix GetFederalRdEstimatedCreditCents that runs twice if tax year has FED and CA state programs **
      const estimateHistory = res?.data?.creditEstimates.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.created === item.created),
      );

      latestManualEstimate = estimateHistory
        .filter(
          (o) =>
            o.payrollDataSource ===
            PayrollDataSourceEnum.MANUAL_VIA_MS_EMPLOYEE,
        )
        .sort((a, b) => b.id - a.id)[0];

      if (latestManualEstimate) {
        const latestManualEstimateDate = latestManualEstimate.created
          ? new Date(latestManualEstimate.created).getTime()
          : undefined;

        if (
          latestManualEstimateDate &&
          latestManualEstimateDate > yeaDeadline
        ) {
          return {
            latestManualEstimate,
          };
        }
      }
    }
  }

  public getCreditNotYetRedeemed() {
    const programsInRedemption = this.company.programs.filter(
      (p) =>
        (p.stage === ProgramStageEnum.CLIENT_REVIEW ||
          p.stage === ProgramStageEnum.FINISHED ||
          p.stage === ProgramStageEnum.COMPLETED) &&
        !p.payrollProviderSetupCompleted8974,
    );

    let totalTaxCredits = 0;

    if (programsInRedemption.length > 0) {
      programsInRedemption.forEach(
        (p) => (totalTaxCredits += p.creditAmountCents),
      );

      runInAction(() => {
        this.creditNotYetRedeemed = totalTaxCredits;
      });
    }
  }
}
